/* eslint-disable no-console */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { InputSelect } from '~/components/NovosInputs';
import { SelectType } from '~/components/NovosInputs/InputAsyncSelect/protocols';
import { useFullScreen } from '~/pages/PdvOnline/hooks/useFullScreen';
import { usePdvOnline } from '~/pages/PdvOnline/hooks/usePdvOnline';
import { useRedirectUrl } from '~/pages/PdvOnline/hooks/useRedirectUrl';
import { aberturaPdv } from '~/pages/PdvOnline/services/abertura-pdv';
import { InitialStatePdv } from '~/pages/PdvOnline/types/context';
import { isUndefinedOrNull } from '~/pages/PdvOnline/utils/isUndefinedOrNull';
import { Modal } from '..';
import { ButtonOk, ModalContent, OperadorCaixa } from './styles';
import { useMediaQuery } from '~/hooks/useMediaQuery';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { buscarElemento } from '~/pages/PdvOnline/utils/buscarElemento';

const MySwal = withReactContent(Swal);

export const ModalOperador: React.FC = () => {
  const {
    toggleStatusPdv,
    isPdvOpen,
    handleInitialStatePdv,
    isModalOpen,
    onOpenModal,
    onCloseModal,
  } = usePdvOnline();

  const { toggleFullScreen, isFullScreen } = useFullScreen();
  const { navigateTo } = useRedirectUrl();
  const [lojas, setLojas] = useState<SelectType[]>([]);
  const [operadores, setOperadores] = useState<SelectType[]>([]);
  const [pdvs, setPdvs] = useState<SelectType[]>([]);

  const modalRef = useRef<HTMLElement | null>(null);
  const isMounted = useRef(true);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const lessThan903 = useMediaQuery({ maxWidth: 903 });

  const {
    register,
    control,
    setValue,
    watch,
    resetField,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const loja = watch('cod_loja') as SelectType;
  const operador = watch('cod_operador') as SelectType;
  const caixa = watch('num_pdv') as SelectType;

  const disableButton =
    isUndefinedOrNull(loja?.label) ||
    isUndefinedOrNull(operador?.label) ||
    isUndefinedOrNull(caixa?.label);

  const openModal =
    !isPdvOpen && isModalOpen.isOpen && isModalOpen.modal === 'operadorCaixa';

  const onClose = useCallback(() => {
    onCloseModal();
    toggleStatusPdv(false);
    navigateTo('/app');
    if (isFullScreen) toggleFullScreen();
  }, [
    isFullScreen,
    navigateTo,
    toggleFullScreen,
    toggleStatusPdv,
    onCloseModal,
  ]);

  useEffect(() => {
    if (!isPdvOpen) onOpenModal('operadorCaixa');
  }, [isPdvOpen, onOpenModal]);

  useEffect(() => {
    const inputElement = modalRef.current?.querySelector(
      'input[name="num_pdv"]',
    );

    const handleFocus = async () => {
      await buscarElemento(modalRef, 'num_pdv', true);
    };

    // const handleBlur = () => {
    //   console.log('Input lost focus');
    // };

    if (inputElement) {
      inputElement.addEventListener('focus', handleFocus);
      // inputElement.addEventListener('blur', handleBlur);
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener('focus', handleFocus);
        // inputElement.removeEventListener('blur', handleBlur);
      }
    };
  }, []);

  const handleOk = useCallback(async () => {
    const initialState: InitialStatePdv = {
      cod_loja: loja.value,
      des_loja: loja.label,
      cod_operador: operador.value,
      des_operador: operador.label,
      num_pdv: caixa.value,
      status_pdv: isPdvOpen ? 'ABERTO' : 'FECHADO',
      val_saldo: 0,
    };

    handleInitialStatePdv(initialState);

    const anotherOp = await aberturaPdv.checkCaixaOutroOperador(
      caixa.value,
      loja.value,
      Number(initialState.cod_operador),
    );

    if (anotherOp) {
      await MySwal.fire({
        title: 'Caixa está aberto com outro operador.',
        text: 'Aguarde o fechamento para prosseguir.',
        icon: 'warning',
      }).then(async () => {
        resetField('cod_operador');

        setTimeout(() => {
          resetField('num_pdv');
        }, 130);

        await buscarElemento(modalRef, 'cod_operador', true);
      });
      return;
    }

    const caixaFechado = await aberturaPdv.checkCaixaFechado(
      caixa.value,
      loja.value,
    );

    // const hasCertificate = await aberturaPdv.getCertificado(loja.value);

    if (!caixaFechado) {
      const sameOp = await aberturaPdv.checkCaixaMesmoOperador(
        caixa.value,
        loja.value,
        Number(initialState.cod_operador),
      );

      if (Object.keys(sameOp).length === 0) {
        // if (!hasCertificate) {
        //   onOpenModal('certificadoDigital');
        //   return;
        // }

        resetField('cod_operador');
        resetField('num_pdv');

        onOpenModal('aberturaPdv');
        return;
      }

      handleInitialStatePdv({ ...initialState, val_saldo: sameOp.val_saldo });
      toggleStatusPdv(true);
      onCloseModal();
    } else {
      resetField('cod_operador');
      resetField('num_pdv');

      // if (!hasCertificate) {
      //   onOpenModal('certificadoDigital');
      //   return;
      // }

      const sameOp = await aberturaPdv.checkCaixaMesmoOperador(
        caixa.value,
        loja.value,
        Number(initialState.cod_operador),
      );

      if (Object.keys(sameOp).length === 0) {
        // if (!hasCertificate) {
        //   onOpenModal('certificadoDigital');
        //   return;
        // }

        resetField('cod_operador');
        resetField('num_pdv');

        onOpenModal('aberturaPdv');
      }
    }
  }, [
    caixa?.value,
    handleInitialStatePdv,
    isPdvOpen,
    loja?.label,
    loja?.value,
    onCloseModal,
    onOpenModal,
    operador?.label,
    operador?.value,
    resetField,
    toggleStatusPdv,
  ]);

  const findDefaultStore = useCallback(async () => {
    const stores = await aberturaPdv.getLojas();

    if (isMounted.current) {
      setLojas(
        stores
          .filter((st) => !st.flg_inativa)
          .map(({ des_loja, cod_loja }) => ({
            label: `${cod_loja} - ${des_loja}`,
            value: cod_loja,
          })),
      );

      const storedefault = stores.filter((s) => s.flg_padrao);

      const value = storedefault.map((s) => ({
        label: `${s.cod_loja} - ${s.des_loja}`,
        value: s.cod_loja,
      }));

      setValue('cod_loja', value[0]);
    }
  }, [setValue]);

  const operdaorPorLoja = useCallback(async () => {
    if (!loja) return;

    const op = await aberturaPdv.getOperador(loja.value);

    if (op.length === 0) {
      resetField('cod_operador');
      setOperadores([]);
      return;
    }

    setOperadores(
      op.map(({ des_operador, cod_operador }) => ({
        label: des_operador ?? '',
        value: cod_operador,
      })),
    );
  }, [loja, resetField]);

  const pdvPorLoja = useCallback(async () => {
    if (!loja) return;

    const caixas = await aberturaPdv.getCaixa(loja.value);

    if (caixas.length === 0) {
      resetField('num_pdv');
      setPdvs([]);
      return;
    }

    setPdvs(
      caixas.map(({ num_pdv }) => ({
        label: String(num_pdv),
        value: num_pdv,
      })),
    );
  }, [loja, resetField]);

  useEffect(() => {
    findDefaultStore();
  }, []);

  useEffect(() => {
    operdaorPorLoja();
  }, [operdaorPorLoja]);

  useEffect(() => {
    pdvPorLoja();
  }, [pdvPorLoja]);

  useEffect(() => {
    if (modalRef.current && openModal) {
      const inputOperador: HTMLInputElement | null =
        modalRef.current.querySelector('[name="cod_operador"]');
      if (inputOperador) {
        inputOperador.focus();
        inputOperador.click();
      }
    }
  }, [openModal]);

  useEffect(() => {
    if (!disableButton && buttonRef.current) buttonRef.current.focus();
  }, [disableButton]);

  return (
    <Modal
      isOpen={openModal}
      title="Selecione o Operador"
      onClose={onClose}
      backdrop="static"
      showFooter
    >
      <ModalContent ref={modalRef}>
        <div style={{ width: '80%' }}>
          <InputSelect
            label="Loja"
            placeholder="Selecione a loja"
            name="cod_loja"
            register={register}
            isError={!!errors.cod_loja}
            control={control}
            options={lojas}
            changeSelected={async (selected) => {
              setValue('cod_loja', selected);
              setValue('cod_operador', '');
              setValue('cod_operador', { value: '', label: '' });
              setValue('num_pdv', '');
              setValue('num_pdv', { value: '', label: '' });

              const inputOperador = document.querySelector<HTMLInputElement>(
                'input[name="cod_operador"]',
              );
              const inputNumPdv = document.querySelector<HTMLInputElement>(
                'input[name="num_pdv"]',
              );

              if (inputOperador) inputOperador.value = '';
              if (inputNumPdv) inputNumPdv.value = '';

              await buscarElemento(modalRef, 'cod_operador', true, 200);
            }}
          />

          <OperadorCaixa>
            <InputSelect
              label="Operador(a)"
              placeholder="Selecione o(a) operador(a)"
              name="cod_operador"
              register={register}
              isError={!!errors.cod_loja}
              control={control}
              options={operadores}
              changeSelected={async (selected) => {
                setValue('cod_operador', selected);
                await buscarElemento(modalRef, 'num_pdv', true, 200);
              }}
              style={{ width: lessThan903 ? 'auto' : '15.625rem' }}
            />
            <InputSelect
              label="Caixa"
              placeholder="Selecione o caixa"
              name="num_pdv"
              register={register}
              isError={!!errors.cod_loja}
              control={control}
              options={pdvs}
              changeSelected={async (selected) => {
                setValue('num_pdv', selected);
                await buscarElemento(null, 'btnok-modaloperador', false, 200);
              }}
            />
          </OperadorCaixa>
        </div>
        <ButtonOk
          ref={buttonRef}
          disabled={disableButton}
          onClick={handleOk}
          type="button"
          id="btnok-modaloperador"
        >
          OK
        </ButtonOk>
      </ModalContent>
    </Modal>
  );
};
