import React, { ReactNode } from 'react';
import { CloseButton, ModalHeader, ModalTitle } from './styles';

import { Modal as BootstrapModal } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';

interface ModalProps {
  isOpen: boolean;
  onClose?: () => void;
  title?: string;
  children: ReactNode;
  backdrop?: boolean | 'static';
  keyboard?: boolean;
  size?: 'sm' | 'lg' | 'xl';
  showFooter?: boolean;
}

export const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  title,
  children,
  backdrop = undefined,
  keyboard = false,
  size = undefined,
  showFooter = false,
}) => {
  return (
    <BootstrapModal
      show={isOpen}
      onHide={onClose}
      centered
      backdrop={backdrop}
      keyboard={keyboard}
      size={size}
    >
      <BootstrapModal.Header>
        <ModalHeader>
          {title && <ModalTitle>{title}</ModalTitle>}
          {onClose && (
            <CloseButton onClick={onClose}>
              <AiOutlineClose />
            </CloseButton>
          )}
        </ModalHeader>
      </BootstrapModal.Header>

      <BootstrapModal.Body>{children}</BootstrapModal.Body>
      {showFooter && (
        <BootstrapModal.Footer>
          <div>
            <a
              href="https://ajuda.konvix.com.br/portal/pt-br/kb/articles/pdv-online"
              target="_blank"
              rel="noreferrer noopener"
              style={{
                textDecoration: 'none',
                fontSize: '0.875rem',
                lineHeight: '1.3125rem',
              }}
            >
              Precisa de ajuda?
            </a>
          </div>
        </BootstrapModal.Footer>
      )}
    </BootstrapModal>
  );
};
