import styled from 'styled-components';

export const ModalContent = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5625rem;

  @media only screen and (max-width: 903px) {
    width: auto;
  }
`;

export const OperadorCaixa = styled.div`
  display: flex;
  gap: 0.75rem;
`;

export const ButtonOk = styled.button`
  background-color: #009e10;
  color: white;
  border: none;
  border-radius: 0.3125rem;

  width: 6.25rem;
  height: 6.25rem;
  margin-top: 1.75rem;

  font-size: x-large;

  &:hover {
    background-color: #029411;
  }

  &:disabled {
    opacity: 50%;
    cursor: not-allowed;

    &:hover {
      background-color: #009e10;
    }
  }
`;
