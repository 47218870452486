import { Button } from 'react-bootstrap';
import styled from 'styled-components';

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ModalTitle = styled.h5`
  margin: 0;
  font-size: 1.25rem;
`;

export const ModalBody = styled.div`
  padding: 1.25rem;
`;

export const CloseButton = styled(Button)`
  background: none;
  border: none;
  background-color: transparent !important;
  padding: 0;
  color: #6c757d;
  font-size: 1.5rem;
  &:hover {
    color: #000;
  }
`;
